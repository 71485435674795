import React,{useState} from 'react';
import Styles from '../Style/Common.module.css';
import BtnStyle from '../Style/Button.module.css'
const OtherDetail = ({ prevStep, handleChange, formData, nextStep , errors,experience, updateExperience,updateResume,sanitizeInput }) => {

  //const [experience, setExperience] = useState([{ expertise: '', yearsOfExperience: '' }]);
  //console.log(experience)
  const [resume, setResume] = useState(null); 
  const handleExperienceChange = (index, event) => {
    const { name, value } = event.target;
    const updatedExperience = [...experience];
    updatedExperience[index][name] = value;
    updateExperience(updatedExperience);
  };

  // Function to add a new experience entry
  const addExperience = () => {
    updateExperience([...experience, { expertise: '', yearsOfExperience: '' }]);
  };

  // Handler to remove an experience entry
  const removeExperience = (index) => {
    const updatedExperience = [...experience];
    updatedExperience.splice(index, 1);
    updateExperience(updatedExperience);
  };

  // Handle resume file upload
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
      setResume(file);
      updateResume(file); // Pass the resume file to parent component
    } else {
      alert('Please upload a valid file in PDF or DOC format.');
    }
  };
  return (
    <div>
      <h6>Experience & CTC Details</h6>
      
      <h5>Experience</h5>
      {experience && experience.map((exp, index) => (
        <div key={index}>
          <div className={Styles.FormControl}>
          <div className={Styles.group}>
          <input
            name="expertise"
            placeholder="Expertise"
            maxLength={255}
            value={sanitizeInput(exp.expertise)}
            onChange={(e) => handleExperienceChange(index, e)}
            required
          />
          {errors.expertise && <span>{errors.expertise}</span>}
          </div>
          <div className={Styles.group}>
          <input
            name="yearsOfExperience"
            type="number"
            placeholder="Years of Experience"
            value={sanitizeInput(exp.yearsOfExperience)}
            onChange={(e) => handleExperienceChange(index, e)}
            required
          />
          {errors.yearsOfExperience && <span>{errors.yearsOfExperience}</span>}
          </div>
          <div className={Styles.group}>
            <button className={Styles.removeBtn} type="button" onClick={() => removeExperience(index)}>Remove</button>
          </div>
          </div>
        </div>
      ))}
      <button type="button" className={Styles.addBtn} onClick={addExperience}>Add More Experience</button>

      <h6>CTC Details</h6>
      <div className={Styles.FormControl}>
        <div className={Styles.group}>
        <input
          type="text"
          name="currentCTC"
          maxLength={255}
          placeholder="Current CTC"
          value={sanitizeInput(formData.ctcDetails.currentCTC)}
          onChange={handleChange('currentCTC', 'ctcDetails')}
        />
        {errors.currentCTC && <span>{errors.currentCTC}</span>}
        </div>
        <div className={Styles.group}>
        <input
          type="text"
          name="netSalary"
          maxLength={255}
          placeholder="Net Salary"
          value={sanitizeInput(formData.ctcDetails.netSalary)}
          onChange={handleChange('netSalary', 'ctcDetails')}
        />
        {errors.netSalary && <span>{errors.netSalary}</span>}
        </div>
        </div>

      
      <div className={Styles.FormControl}>
        <div className={Styles.group}>
        <h6>Joining Time</h6>
      <select
          name="joiningTime"
          value={formData.joiningTime}
          onChange={handleChange('joiningTime', '')}
          required
        >
          <option value="">Select Joining Time</option>
          <option value="Immediate">Immediate</option>
          <option value="15 days">15 days</option>
          <option value="30 days">30 days</option>
          <option value="more than 30 days">More than 30 days</option>
          <option value="Serving Notice period">Serving Notice period</option>
        </select>
      {errors.joiningTime && <span>{errors.joiningTime}</span>}
      </div>

      <div className={Styles.group + " " + Styles.uploadFile}>
        <h6>Upload Resume (PDF/DOC):</h6>
        <input
          type="file"
          accept=".pdf, .doc, .docx"
          onChange={handleFileChange}
        />
        {resume && <p>Uploaded file: {resume.name}</p>}
      </div>
      </div>
      <button onClick={prevStep} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Previous</button>
      <button type="button" onClick={nextStep} className={BtnStyle.btn + ' ' + BtnStyle.RedBtn}>Next</button>
    </div>
  );
};

export default OtherDetail;
